import { readonly, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { Product } from '~/modules/catalog/product/types';
import { getData, setData } from 'nuxt-storage/local-storage';
import type {
  UseRecentProductAddItemParams,
  UseRecentProductErrors,
  UseRecentProductsInterface,
  UseRecentProductRemoveItemParams,
} from './useRecentProducts';

/**
 * Allows loading and manipulating recentproduct of the current user.
 *
 * See the {@link UseRecentProductsInterface} for a list of methods and values available in this composable.
 */
export function useRecentProducts(): UseRecentProductsInterface {
  const loading = ref(false);

  const error = ref<UseRecentProductErrors>({
    load: null,
    addItem: null,
    removeItem: null,
    clear: null,
  });

  const load = () => {
    Logger.debug('useRecentProducts/load');
    let results = null;
    try {
      loading.value = true;
      if (!process.client) return;
      results = getData('recent-product') || [];
      error.value.load = null;
    } catch (err) {
      error.value.load = err;
      Logger.error('Magento] recent product error', err);
    } finally {
      loading.value = false;
    }
    return results;
  };

  // eslint-disable-next-line consistent-return
  const addItem = async ({ product }: UseRecentProductAddItemParams) => {
    Logger.debug('useRecentProducts/addItem');
    const results: any = getData('recent-product') || [];
    const exist: Boolean = results.some((item: Product) => item.uid == product.uid);
    if (exist) return;
    results.unshift(product);
    setData('recent-product', results);
    return results;
  };

  const removeItem = async ({ product }: UseRecentProductRemoveItemParams) => {
    Logger.debug('useRecentProducts/removeItem');
    const results: any = getData('recent-product') || [];
    const index = results.findIndex((item: Product) => item.uid === product.uid);
    results.splice(index, 1);
    setData('recent-product', results);
    return results;
  };
  // eslint-disable-next-line @typescript-eslint/require-await
  const clear = async () => {
    Logger.debug('useWuseRecentProductsishlist/clear');
  };

  return {
    load,
    addItem,
    removeItem,
    clear,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useRecentProducts;
export * from './useRecentProducts';
