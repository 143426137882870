export default `
    query GetSlider($id: Int) {
      amGetSlider(id: $id) {
        animation_effect
        banners {
          hover_text
          id
          image_alt
          image_path
          name
          target_type
          target_url
          visible_on
        }
        id
        name
        bullets_style
        navigation_arrows
        navigation_bullets
      }
    }
`;
