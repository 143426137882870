var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"codeof-week"},[_c('div',{staticClass:"container"},[_c('h3',{staticClass:"h3 title"},[_vm._v("\n      "+_vm._s(_vm.codeData.name)+"\n    ")]),_vm._v(" "),(!_vm.codeData)?_c('div',{staticClass:"no-data"}):_c('div',{staticClass:"code-carousel"},[_c('SfCarousel',{staticClass:"carousel",attrs:{"settings":{
          type: 'carousel',
          perView: 4,
          focusAt: 'center',
          gap: 32,
          rewind: false,
          slidePerPage: false,
          breakpoints: { 1023: { perView: 1.5, peek: 0 } },
        }}},[_vm._l((_vm.codeData.banners),function(slider,i){return _c('SfCarouselItem',{key:i,staticClass:"carousel__item"},[_c('SfLink',{attrs:{"link":slider.target_url}},[_c('img',{attrs:{"src":slider.image_path,"alt":slider.image_alt || 'Unineed.com',"loading":"eager","width":"288","height":"288"}}),_vm._v(" "),_c('h4',{staticClass:"slider-title"},[_vm._v(_vm._s(slider.name))]),_vm._v(" "),_c('p',{staticClass:"sf-card__description"},[_vm._v("\n                "+_vm._s(slider.image_alt)+"\n              ")])])],1)})],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }