var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home-carousel",style:(("width: " + _vm.viewWidth))},[_c('SfCarousel',{staticClass:"carousel",attrs:{"settings":{
      type: 'carousel',
      rewind: false,
      perView: 1,
      autoplay: false,
      hoverpause: true,
      gap: 0,
      breakpoints: { 1023: { perView: 1 } },
    }}},[(_vm.isDesktop)?_vm._l((_vm.desktopSliders),function(slider,i){return _c('SfCarouselItem',{directives:[{name:"show",rawName:"v-show",value:(_vm.isDesktop),expression:"isDesktop"}],key:i,staticClass:"carousel__item"},[_c('SfLink',{attrs:{"link":slider.target_url}},[_c('img',{staticClass:"desktop-only",attrs:{"src":((slider.image_path) + "?resize=p_2,w_1440"),"alt":slider.image_alt || 'uninned',"loading":i === 0 ? 'eager' : 'lazy',"width":"1440","height":"375"}})])],1)}):_vm._e(),_vm._v(" "),(!_vm.isDesktop)?_vm._l((_vm.mobileSliders),function(slider,i){return _c('SfCarouselItem',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isDesktop),expression:"!isDesktop"}],key:i,staticClass:"carousel__item"},[_c('img',{staticClass:"smartphone-only",attrs:{"src":slider.image_path,"alt":slider.image_alt || 'uninned'}})])}):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }