















































import { defineComponent, PropType, useContext } from '@nuxtjs/composition-api';
import { SfCarousel, SfCard, SfButton, SfLink } from '@storefront-ui/vue';
import { Promotion } from '~/queries/types';
import useUiNotification from '~/composables/useUiNotification';

export default defineComponent({
  name: 'CodeOfTheWeekCarousel',
  components: {
    SfCarousel,
    SfCard,
    SfButton,
    SfLink,
  },
  props: {
    codeData: {
      type: Object as PropType<Promotion | null>,
      required: true,
      default: () => null,
    },
    locale: {
      type: String,
      default: 'gb',
    },
  },
  setup(props) {
    const { send: sendNotification } = useUiNotification();
    const {
      app: { i18n },
      $config: { currentSite },
    } = useContext();
    const context = useContext();
    // eslint-disable-next-line @typescript-eslint/require-await, consistent-return
    const getCodeAttributeData = async (code: string) => {
      if (currentSite === 'cn') {
        const regex = /[\u4e00-\u9fa5]/g;
        const result = code.replace(regex, '');
        if (result) return result;
      } else {
        const pattern = /code: (.+)/i;
        const match = code.match(pattern);
        if (match && match[1]) return match[1];
      }
    };

    // eslint-disable-next-line consistent-return
    const onCopy = async (text: string) => {
      try {
        const code = await getCodeAttributeData(text);
        // @ts-ignore
        context.$clipboard(code);
        sendNotification({
          id: Symbol('copy_code'),
          message: i18n.t('Copied to Clipboard') as string,
          persist: false,
          type: 'secondary',
          icon: 'info',
        });
      } catch (err) {
        console.error('Failed to copy text:', err);
      }
    };

    return {
      onCopy,
    };
  },
});
