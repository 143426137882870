










































import {
  defineComponent,
  ref,
  onMounted,
  useFetch,
  useContext,
} from '@nuxtjs/composition-api';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { CmsPage } from '~/modules/GraphQL/types';
import HomeCarousel from '~/components/HomeCarousel.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import { useContent } from '~/composables';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { useApi } from '~/composables/useApi';
import getSliderGql from '~/queries/getSlider.gql';
import { SliderItem, Promotion } from '~/queries/types';
import CodeOfTheWeekCarousel from '~/components/Promotion/CodeOfTheWeekCarousel.vue';
import PromotionActivities from '~/components/Promotion/PromotionActivities.vue';
import { useI18n } from '~/helpers/hooks/usei18n';
import type { Product } from '~/modules/catalog/product/types';
import { useRecentProducts } from '~/modules/catalog/product/composables/useRecentProducts';
// import CNHomeActivities from '~/components/Promotion/CNHomeActivities.vue';

export default defineComponent({
  name: 'HomePage',
  components: {
    HomeCarousel,
    LoadWhenVisible,
    CodeOfTheWeekCarousel,
    PromotionActivities,
    // CNHomeActivities,
    // eslint-disable-next-line vue/no-unused-components
    RecentProducts: () =>
      import(/* webpackPrefetch: true */ '~/components/RecentProducts.vue'),
    NewProducts: () =>
      import(/* webpackPrefetch: true */ '~/components/NewProducts.vue'),
    // ReducedProducts: () =>
    //   import(/* webpackPrefetch: true */ '~/components/ReducedProducts.vue'),
    FeaturedProducts: () =>
      import(/* webpackPrefetch: true */ '~/components/FeaturedProducts.vue'),
    ShopByCategory: () =>
      import(/* webpackPrefetch: true */ '~/components/ShopByCategory.vue'),
  },
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setup() {
    const { addTags } = useCache();
    const { loadPage } = useContent();
    const { query } = useApi();
    const {
      $config: { currentSite },
    } = useContext();
    const page = ref<CmsPage | null>(null);
    const sliders = ref<SliderItem[] | Array<any>>([]);
    const codeSliders = ref<Promotion | any>({ name: '', id: 0, banners: [] });
    const promotions = ref<Promotion | Object>({ name: '' });
    const { locale } = useI18n();
    const recentProducts = ref<Product[]>([]);

    const { load: loadRecentProduct, removeItem: removeRecentProduct } =
      useRecentProducts();
    // fetch home slider
    const fetchSliderData = async () => {
      // sliderid us :11 ,cn gb: 1, mx:22
      console.log('locale', locale);
      let sliderId;
      if (locale === 'gb' || currentSite === 'cn') {
        sliderId = 1;
      } else if (locale === 'mex') {
        sliderId = 22;
      } else {
        sliderId = 11;
      }
      const { data, errors } = await query<any>(getSliderGql, { id: sliderId });
      if (errors) {
        console.log('**** fetchSliderData Errors', JSON.stringify(errors));
      }
      if (data?.amGetSlider?.banners?.length > 0) {
        console.log('*fetchSliderData SUCCESS');
        sliders.value = data?.amGetSlider?.banners as SliderItem[];
      }
    };
    // fetch code of week slider
    const fetchCodeSliderData = async () => {
      // sliderid us 12 : 2,
      let id;
      if (locale === 'gb' || currentSite === 'cn') {
        id = 2;
      } else if (locale === 'mex') {
        id = 21;
      } else {
        id = 12;
      }
      const { data, errors } = await query<any>(getSliderGql, { id });
      if (errors) {
        console.log('**** fetchCodeSliderData Errors', JSON.stringify(errors));
      }
      if (data?.amGetSlider?.banners?.length > 0) {
        // console.log('&&& fetchCodeSliderData SUCCESS:', data?.amGetSlider);
        codeSliders.value = data?.amGetSlider;
      }
    };

    // fetch code of week slider
    const fetchPromtionData = async () => {
      // sliderid us 13 : 3,
      let id;
      if (locale === 'gb' || currentSite === 'cn') {
        id = 3;
      } else if (locale === 'mex') {
        id = 23;
      } else {
        id = 13;
      }
      const { data, errors } = await query<{ amGetSlider: Promotion }>(
        getSliderGql,
        {
          id,
        }
      );
      if (errors) {
        console.log('**** fetchPromtionData Errors', JSON.stringify(errors));
      }
      if (data?.amGetSlider?.banners?.length > 0) {
        // console.log('&&& fetchPromtionData SUCCESS:', data?.amGetSlider);
        promotions.value = data?.amGetSlider;
      }
    };
    const loadRecentProducts = async () => {
      const result: any = await loadRecentProduct();
      recentProducts.value = result;
    };
    const removeRecentProductItem = async (product: Product) => {
      const result: any = await removeRecentProduct({ product });
      recentProducts.value = result;
    };

    useFetch(async () => {
      await fetchSliderData();
      await fetchCodeSliderData();
    });
    onMounted(async () => {
      // await fetchSliderData();
      // await fetchCodeSliderData();
      await fetchPromtionData();
      await loadRecentProducts();
      page.value = await loadPage({ identifier: 'home' });
    });

    onMounted(() => {
      addTags([{ prefix: CacheTagPrefix.View, value: 'home' }]);
    });

    // @ts-ignore
    return {
      page,
      sliders,
      codeSliders,
      promotions,
      recentProducts,
      removeRecentProductItem,
      locale,
      currentSite,
    };
  },
  head() {
    return getMetaInfo(this.page);
  },
});
